<template>
  <div class="main-wrap" v-webTitle :data-title="textTitle">
    <!-- 通用顶部 -->
    <generalTop></generalTop>

    <!-- 中间区域 -->
    <div class="center-wrap">
      <div class="left">
        <div class="top">
          <span>欢迎您！</span>
          <span>{{ account }}</span>
        </div>
        <div class="bottom">
          <div class="manage-wrap">
            <div class="order-manage">
              <span>订单管理</span>
              <ul>
                <li id="menu1" @click="noPay">待付款</li>
                <li id="menu2" @click="isPay">已付款</li>
              </ul>
            </div>
            <div class="account-manage">
              <span>账号管理</span>
              <ul>
                <li id="menu3" @click="editInfo">修改资料</li>
                <li id="menu4" @click="editPwd">修改密码</li>
                <li id="menu5" @click="logout">退出登录</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="all-wrap">
          <!-- 搜索区 -->
          <div class="search-wrap">
            <div class="search-div1">
              <div class="goods-info-wrap">
                <span>商品信息：</span>
                <el-input v-model="search.contentName"></el-input>
              </div>
              <div class="order-time">
                <span>下单时间：</span>
                <el-date-picker
                  v-model="searchDate"
                  type="daterange"
                  range-separator="~"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                >
                </el-date-picker>
              </div>
            </div>
            <div class="search-div2">
              <div class="order-num">
                <span>订单编号：</span>
                <el-input v-model="search.orderNo"></el-input>
              </div>
              <div class="order-price">
                <span>订单金额：</span>
                <div class="priceScope">
                  <el-input v-model="search.amountMin"></el-input>
                  <span>~</span>
                  <el-input v-model="search.amountMax"></el-input>
                </div>
              </div>
            </div>
            <div class="search-btns">
              <div class="search-btn" @click="searchBtn">查询</div>
              <div class="reset-btn" @click="reset">重置</div>
            </div>
          </div>

          <!-- 表格区 -->
          <div class="table-wrap">
            <el-table
              :data="tableData"
              style="width: 100%"
              height="280"
              :header-cell-style="{ textAlign: 'center' }"
              :cell-style="{ textAlign: 'center' }"
            >
              <el-table-column prop="orderNo" label="订单编号" width="210">
              </el-table-column>
              <el-table-column prop="title" label="商品信息" width="300">
              </el-table-column>
              <el-table-column
                prop="createTime"
                label="下单时间"
                :formatter="dateFormat"
                width="210"
              >
              </el-table-column>
              <el-table-column prop="amount" label="订单金额">
              </el-table-column>
              <el-table-column label="状态" prop="stateDesc">
                <template slot-scope="scope">
                  <div
                    v-if="scope.row.stateDesc == '已付款'"
                    style="color: #4eff45"
                  >
                    {{ scope.row.stateDesc }}
                  </div>
                  <div
                    v-if="scope.row.stateDesc == '待付款'"
                    style="color: #f08000; cursor: pointer"
                    @click="
                      handlePay(
                        scope.row,
                        scope.row.contentId,
                        scope.row.amount
                      )
                    "
                  >
                    {{ scope.row.stateDesc }}
                  </div>
                  <div
                    v-if="scope.row.stateDesc == '取消支付'"
                    style="color: #606266"
                  >
                    {{ scope.row.stateDesc }}
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>

          <!-- 分页 -->
          <div class="pagination-wrap">
            <el-pagination
              @current-change="handleCurrentChange"
              :current-page="search.pageNum"
              :page-size="search.pageSize"
              layout="prev, pager, next"
              :total="totalCount"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>

    <!-- 修改资料蒙版 -->
    <div class="edit-info" v-show="showEditInfo">
      <div class="edit-info-exit">
        <i class="iconfont icon-cha" @click="exitEdit"></i>
      </div>

      <div class="edit-box">
        <span>修改资料</span>

        <div class="typeSelect">
          <el-radio v-model="userType" size="medium" label="1"
            >个人用户</el-radio
          >
          <el-radio v-model="userType" size="medium" label="2"
            >企业用户</el-radio
          >
        </div>

        <div class="privateUser" v-if="userType == '1'">
          <div class="username">
            <span>姓名:</span>
            <el-input v-model="username"></el-input>
          </div>

          <div class="idNum">
            <span>身份证号:</span>
            <el-input v-model="idNum"></el-input>
          </div>
        </div>

        <div class="companyUser" v-else>
          <div class="company-name">
            <span>公司名称:</span>
            <el-input v-model="companyName"></el-input>
          </div>

          <div class="company-num">
            <span>统一信用代码:</span>
            <el-input v-model="companyNum"></el-input>
          </div>

          <div class="company-mail">
            <span>电子邮箱:</span>
            <el-input v-model="companyMail"></el-input>
          </div>
        </div>
      </div>

      <div class="submit-btn" @click="handleSubmitEdit">保存</div>
    </div>

    <!-- 修改密码蒙版 -->
    <div class="edit-info" v-show="showEditPwd">
      <div class="edit-info-exit">
        <i class="iconfont icon-cha" @click="exitEdit"></i>
      </div>

      <div class="edit-box">
        <span>修改密码</span>

        <div class="old-pwd">
          <span>原密码:</span>
          <el-input v-model="oldPwd" show-password></el-input>
        </div>

        <div class="new-pwd">
          <span>新密码:</span>
          <el-input v-model="newPwd" show-password></el-input>
        </div>

        <div class="new-pwd-confirm">
          <span>确认新密码:</span>
          <el-input v-model="newPwdConfirm" show-password></el-input>
        </div>
      </div>

      <div class="submit-btn" @click="handleSubmitNewPwd">保存</div>
    </div>

    <!-- 支付蒙版 -->
    <el-dialog
      title="微信扫描二维码支付"
      :visible.sync="showMask"
      width="30%"
      :before-close="handleClose"
      class="pay-info"
    >
      <div class="pay-box">
        <div id="qrcode" ref="qrcode"></div>
        <span>订单金额：{{ amount }}元</span>
      </div>
    </el-dialog>

    <!-- 底部区 -->
    <footbar></footbar>

    <!-- 侧边栏 -->
    <sidebar></sidebar>
  </div>
</template>

<script>
import generalTop from "../../components/GeneralTop/index";
import footbar from "../../components/Footbar/index";
import sidebar from "../../components/Sidebar/index";
import {
  handleEditInfo,
  handleEditPwd,
  handleOrderList,
} from "@/api/MemberCenter";
import Cookies from "js-cookie";
import { pay, ifBuy } from "@/api/ResourceCenter";
import QRCode from "qrcodejs2";
import { getIfPay } from "@/api/im";

export default {
  components: {
    generalTop,
    footbar,
    sidebar,
  },

  data() {
    return {
      // 页面标题
      textTitle: "会员中心",

      //   表格数据
      tableData: [],

      // 修改蒙版可见性
      showEditInfo: false,
      showEditPwd: false,

      // 用户类型
      userType: "1",

      // 个人姓名
      username: "",

      //身份证号
      idNum: "",

      // 公司名称
      companyName: "",

      // 统一信用代码
      companyNum: "",

      // 电子邮箱
      companyMail: "",

      // 原密码
      oldPwd: "",

      // 新密码
      newPwd: "",

      // 确认新密码
      newPwdConfirm: "",

      // 用户账户
      account: "",

      // 总条数
      totalCount: 0,

      // 时间区间
      searchDate: "",

      // 查询参数
      search: {
        createTimeStart: "", //订单起时间
        createTimeEnd: "", //订单止时间
        orderNo: "", //订单编号
        contentName: "", //商品信息
        pageSize: 5, //每页条数
        pageNum: 0, //当前页
        amountMax: null, //最大金额
        amountMin: null, //最小金额
        state: null, //订单状态
      },

      // 边栏四个菜单
      e1: null,
      e2: null,
      e3: null,
      e4: null,

      // 支付蒙版可见性
      showMask: false,

      // 待支付金额
      amount: 0,

      // 定时器，扫描支付状态
      timer: null,

      // 待支付资源id
      id: null,
    };
  },

  mounted() {
    this.getUserInfo();
    this.getOrderList();
    this.handleMenus();

    // 每次进入界面时，先清除之前的所有定时器，然后启动新的定时器
    clearInterval(this.timer);
    this.timer = null;
  },

  destroyed() {
    // 每次离开当前界面时，清除定时器
    this.closeTimer();
  },

  methods: {
    // 获取边栏菜单
    handleMenus() {
      this.e1 = document.querySelector("#menu1");
      this.e2 = document.querySelector("#menu2");
      this.e3 = document.querySelector("#menu3");
      this.e4 = document.querySelector("#menu4");
    },

    // 获取登录状态及用户信息
    getUserInfo() {
      if (Cookies.get("Admin-Token") == undefined) {
        this.$router.push({ path: "/MemberCenter/LoginAndReg" });
      } else {
        this.account = Cookies.get("account");
      }
    },

    // 修改资料
    editInfo() {
      this.e1.classList.remove("active");
      this.e2.classList.remove("active");
      this.e3.classList.add("active");
      this.e4.classList.remove("active");

      this.showEditInfo = true;
      this.showEditPwd = false;
    },

    // 提交修改资料内容
    handleSubmitEdit() {
      const data = {
        companyName: this.companyName,
        creditCode: this.companyNum,
        email: this.companyMail,
        name: this.username,
        idCard: this.idNum,
      };
      handleEditInfo(data).then((res) => {
        if (res.code === 200) {
          this.$message({
            message: "资料修改成功",
            type: "success",
          });
        }
      });
    },

    // 提交修改密码
    handleSubmitNewPwd() {
      if (this.newPwd !== this.newPwdConfirm) {
        this.$message({
          message: "两次密码输入不一致",
          type: "error",
        });
      } else {
        const data = {
          oldPasword: this.oldPwd,
          newPassword: this.newPwd,
        };
        handleEditPwd(data).then((res) => {
          if (res.code === 200) {
            this.$message({
              message: "密码修改成功",
              type: "success",
            });
          }
        });
      }
    },

    // 修改密码
    editPwd() {
      this.e1.classList.remove("active");
      this.e2.classList.remove("active");
      this.e3.classList.remove("active");
      this.e4.classList.add("active");

      this.showEditInfo = false;
      this.showEditPwd = true;
    },

    // 关闭修改资料/关闭修改密码
    exitEdit() {
      this.companyName = "";
      this.companyNum = "";
      this.companyMail = "";
      this.showEditInfo = false;

      this.oldPwd = "";
      this.newPwd = "";
      this.newPwdConfirm = "";
      this.showEditPwd = false;
    },

    // 用户退出
    logout() {
      Cookies.remove("account");
      Cookies.remove("Admin-Token");
      Cookies.remove("user_Id");
      localStorage.clear();
      this.$router.push({ path: "/MemberCenter/LoginAndReg" });
    },

    // 获取订单列表
    getOrderList() {
      handleOrderList(this.search).then((res) => {
        // console.log(res);
        this.totalCount = res.total;
        this.tableData = res.data;
      });
    },

    // 分页
    handleCurrentChange(newPage) {
      this.search.pageNum = newPage;
      this.getOrderList();
    },

    // 时间格式化
    dateFormat(row, column) {
      var date = row[column.property];
      if (date == undefined) {
        return "";
      }
      return this.$moment(date).format("YYYY-MM-DD HH:mm:ss");
    },

    // 待付款按钮
    noPay() {
      this.e1.classList.add("active");
      this.e2.classList.remove("active");
      this.e3.classList.remove("active");
      this.e4.classList.remove("active");
      this.exitEdit();

      this.search.state = 0;
      this.getOrderList();
    },

    // 已付款按钮
    isPay() {
      this.e1.classList.remove("active");
      this.e2.classList.add("active");
      this.e3.classList.remove("active");
      this.e4.classList.remove("active");
      this.exitEdit();

      this.search.state = 1;
      this.getOrderList();
    },

    // 重置
    reset() {
      this.search = {
        createTimeStart: "",
        createTimeEnd: "",
        orderNo: "",
        contentName: "",
        pageSize: 5,
        pageNum: 0,
        amountMax: null,
        amountMin: null,
        state: null,
      };

      this.searchDate = "";

      this.e1.classList.remove("active");
      this.e2.classList.remove("active");
      this.e3.classList.remove("active");
      this.e4.classList.remove("active");
      this.exitEdit();

      this.getOrderList();
    },

    // 查询
    searchBtn() {
      this.exitEdit();

      // console.log(this.searchDate);
      if (this.searchDate != "") {
        this.search.createTimeStart = this.$moment(this.searchDate[0]).format(
          "YYYY-MM-DD HH:mm:ss"
        );

        this.search.createTimeEnd = this.$moment(this.searchDate[1]).format(
          "YYYY-MM-DD HH:mm:ss"
        );
      }

      this.getOrderList();
    },

    // 在线支付
    handlePay(row, id, amount) {
      // console.log(row);
      if (row.apiType == "03") {
        this.id = id;
        this.amount = amount;

        const data = {
          contentId: this.id,
        };
        // console.log(data);

        this.handleCode(row.codeUrl);
        this.setTimer(row.orderNo);
      }

      if (row.apiType == "01") {
        this.$message({
          message: "请通过手机浏览器进行支付",
          type: "error",
        });
      }

      if (row.apiType == "02") {
        this.$message({
          message: "请通过微信浏览器进行支付",
          type: "error",
        });
      }
    },

    // 生成微信支付二维码
    handleCode(url) {
      this.showMask = true;

      setTimeout(() => {
        new QRCode("qrcode", {
          width: 200,
          height: 200,
          text: url,
        });
      }, 500);
    },

    // 关闭支付窗口
    handleClose() {
      this.$refs.qrcode.innerHTML = "";
      this.showMask = false;

      this.closeTimer();
      this.getOrderList();
    },

    // 定时扫描支付状态
    setTimer(orderNo) {
      if (this.timer == null) {
        this.timer = setInterval(() => {
          getIfPay(orderNo).then((res) => {
            // console.log(res);
            if (res.data.state == 1) {
              this.showMask = false;
              this.getOrderList();
            } else {
              // this.isBuy = false;
            }
          });
        }, 2000);
      }
    },

    // 关闭定时器
    closeTimer() {
      clearInterval(this.timer);
      this.timer = null;
    },
  },
};
</script>

<style scoped>
.main-wrap {
  position: relative;
}

.center-wrap {
  margin: 45px auto 0;
  width: 85%;
  display: flex;
  justify-content: space-between;
}

.center-wrap .left {
  width: 20%;
}

.center-wrap .left .top,
.center-wrap .left .bottom,
.center-wrap .right .all-wrap {
  width: 100%;
  background: #fff;
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.35);
  border-radius: 10px;
}

.center-wrap .left .top {
  height: 105px;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.center-wrap .left .top span {
  font-size: 20px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #5b5b5b;
}

.center-wrap .left .top span:nth-child(2) {
  margin-top: 10px;
  text-align: center;
}

.center-wrap .left .bottom {
  margin-top: 20px;
  height: 337px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.center-wrap .left .bottom .manage-wrap {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.order-manage,
.account-manage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.order-manage span,
.account-manage span {
  font-size: 23px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #383838;
}

.order-manage span {
  margin-bottom: 15px;
}

.account-manage span {
  margin: 15px 0;
}

.order-manage ul li,
.account-manage ul li {
  font-size: 19px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #5b5b5b;
  line-height: 2;
  cursor: pointer;
  border: 2px solid transparent;
}

.active {
  border-bottom: 2px solid #ff8516 !important;
}

.center-wrap .right {
  margin-left: 20px;
  width: 80%;
}

.center-wrap .right .all-wrap {
  height: 517px;
  padding: 20px;
}

.search-wrap {
  width: 100%;
  height: 110px;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #a1a1a1;
}

.search-div1,
.search-div2 {
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 20px;
}

.goods-info-wrap,
.order-time,
.order-num,
.order-price {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.goods-info-wrap span,
.order-time span,
.order-num span,
.order-price span {
  display: block;
  width: 30%;
  text-align: center;
  font-size: 19px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #5b5b5b;
}

.goods-info-wrap .el-input,
.order-time .el-input__inner,
.order-num .el-input,
.priceScope {
  width: 70%;
}

.priceScope {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.priceScope .el-input {
  width: 100%;
}

.priceScope span {
  display: block;
  width: 10%;
}

::v-deep .el-input {
  margin-bottom: 3px;
}

::v-deep .el-input__inner {
  border: none;
  border-bottom: 1px solid #848383;
  border-radius: 0;
  color: #5b5b5b;
}

.order-time ::v-deep .el-input__inner,
.priceScope ::v-deep .el-input__inner {
  border: 1px solid #848383;
  border-radius: 5px;
  color: #5b5b5b;
}

.search-btns {
  width: 10%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.search-btns .search-btn,
.search-btns .reset-btn {
  width: 100%;
  height: 35px;
  border-radius: 10px;
  font-size: 15px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.search-btns .search-btn {
  background: #ff8516;
  color: #ffffff;
}

.search-btns .reset-btn {
  border: 1px solid #ff8516;
  background: #fff;
  color: #000;
}

.table-wrap {
  display: flex;
  justify-content: center;
}

::v-deep .el-table td.el-table__cell,
::v-deep .el-table th.el-table__cell.is-leaf {
  border-bottom: none;
}

::v-deep .el-table::before {
  height: 0px;
}

.pagination-wrap {
  margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.edit-info {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -70%);
  width: 991px;
  background: rgba(56, 56, 56, 0.7);
  z-index: 1;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.edit-info .edit-info-exit {
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.edit-info .edit-info-exit .iconfont {
  font-size: 30px;
  color: #fff;
  cursor: pointer;
}

.edit-info .edit-box {
  margin: 30px;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.edit-info .edit-box > span {
  margin-bottom: 50px;
  font-size: 40px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #fdfdfd;
}

.typeSelect {
  margin-bottom: 50px;
  display: flex;
}

::v-deep .el-radio__input {
  vertical-align: unset;
}

::v-deep .el-radio__inner {
  width: 20px;
  height: 20px;
}

::v-deep .el-radio__label {
  font-size: 29px;
  font-weight: normal;
  color: #fdfdfd;
}

.company-name,
.company-num,
.company-mail,
.old-pwd,
.new-pwd,
.new-pwd-confirm,
.username,
.idNum {
  margin-bottom: 50px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.company-name span,
.company-num span,
.company-mail span,
.old-pwd span,
.new-pwd span,
.new-pwd-confirm span,
.username span,
.idNum span {
  display: block;
  width: 38%;
  font-size: 29px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #fdfdfd;
  text-align: right;
}

.company-name .el-input,
.company-num .el-input,
.company-mail .el-input,
.old-pwd .el-input,
.new-pwd .el-input,
.new-pwd-confirm .el-input,
.username .el-input,
.idNum .el-input {
  margin-left: 20px;
  width: 70%;
}

.company-name ::v-deep .el-input__inner,
.company-num ::v-deep .el-input__inner,
.company-mail ::v-deep .el-input__inner,
.old-pwd ::v-deep .el-input__inner,
.new-pwd ::v-deep .el-input__inner,
.new-pwd-confirm ::v-deep .el-input__inner,
.username ::v-deep .el-input__inner,
.idNum ::v-deep .el-input__inner {
  border: none;
  border-bottom: 2px solid #fdfdfd;
  border-radius: 0;
  background-color: transparent;
  color: #fdfdfd;
  font-size: 29px;
}

.submit-btn {
  width: 118px;
  height: 53px;
  background: #ff8516;
  border-radius: 10px;
  font-size: 24px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.pay-info .pay-box {
  margin: 30px;
  width: 80%;
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 99;
}

.pay-info .pay-box span {
  margin-top: 10px;
  font-size: 24px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: red;
}
</style>
