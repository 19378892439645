import request from '@/utils/request'

// 获取验证码
export function getSmsCode(data) {
    return request({
        url: '/door/getSmsCode',
        headers: {
            isToken: false
        },
        method: 'post',
        data: data
    })
}

// 注册
export function handleUserReg(data) {
    return request({
        url: '/member/register',
        headers: {
            isToken: false
        },
        method: 'post',
        data: data
    })
}

// 登录
export function handleUserLogin(data) {
    return request({
        url: '/member/login',
        method: 'post',
        data: data
    })
}

// 修改资料
export function handleEditInfo(data) {
    return request({
        url: '/member/editMemberInfo',
        method: 'post',
        data: data
    })
}

// 修改密码
export function handleEditPwd(data) {
    return request({
        url: '/member/updatePwsd',
        method: 'post',
        data: data
    })
}

// 获取订单列表
export function handleOrderList(data) {
    return request({
        url: '/member/searchOrderList',
        method: 'post',
        data: data
    })
}