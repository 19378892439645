import request from '@/utils/request'

// 获取栏目数据
export function getColumnData(data) {
    return request({
        url: '/door/searchContentTree',
        headers: {
            isToken: false
        },
        method: 'post',
        data: data
    })
}

// 获取栏目列表
export function getColumnList(data) {
    return request({
        url: '/door/searchContentList',
        headers: {
            isToken: false
        },
        method: 'post',
        data: data
    })
}

// 热门资源
export function getHotList(data) {
    return request({
        url: '/door/searchHotContentList',
        headers: {
            isToken: false
        },
        method: 'post',
        data: data
    })
}

// 下载资源
// export function downloadResource(data) {
//     return request({
//         url: '/member/downContentFile',
//         method: 'post',
//         data: data
//     })
// }

// 支付下载
export function pay(data) {
    return request({
        url: '/member/addOrder',
        method: 'post',
        data: data
    })
}

// 判断资源是否已购买
export function ifBuy(data) {
    return request({
        url: '/member/getContentStatuByUser',
        method: 'post',
        data: data
    })
}

// 更新资源阅读量
export function handleReadCount(id) {
    return request({
        url: `/door/contentInfo/${id}`,
        headers: {
            isToken: false
        },
        method: 'get',
    })
}

// 更新资源下载量
export function handleDownCount(id) {
    return request({
        url: `/member/updateDownCnt/${id}`,
        method: 'get',
    })
}

// 查询资源
export function handleSearchList(data) {
    return request({
        url: '/door/searchContentList',
        method: 'post',
        data: data
    })
}