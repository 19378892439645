<template>
  <div class="main-wrap" v-webTitle :data-title="textTitle">
    <div class="content-wrap">
      <div class="back-img">
        <img src="../../assets/images/top-img.png" alt="" />
        <div class="mask"></div>
      </div>

      <div class="navigatoin-wrap">
        <navigation></navigation>
      </div>

      <!-- 登录或注册区 -->
      <div class="login-wrap">
        <div class="left">
          <div class="left-content-wrap">
            <img src="../../assets/images/logo-color.png" alt="" />
            <span>一站式企业服务平台</span>
            <span>——为您提供优质、高效的服务</span>
          </div>
        </div>
        <div class="right">
          <div class="right-content-wrap">
            <!-- 登录表单 -->
            <div class="login-form-wrap" v-if="isShow" id="loginDiv">
              <div class="title">
                <span>会员登录</span>
              </div>
              <div class="login-form">
                <div class="account">
                  <span class="iconfont icon-denglu"></span>
                  <el-input
                    v-model="account"
                    placeholder="请输入账号/手机号"
                  ></el-input>
                </div>
                <div class="password">
                  <span class="iconfont icon-mima"></span>
                  <el-input
                    v-model="password"
                    placeholder="请输入密码"
                    show-password
                    @keyup.enter.native="handleLogin"
                  ></el-input>
                </div>
                <div class="login-btn" @click="handleLogin">登录</div>
                <div class="other">
                  <span>还没有账号？</span>
                  <span @click="gotoReg">免费注册</span>
                </div>
              </div>
            </div>

            <!-- 注册表单 -->
            <div class="reg-form-wrap" id="regDiv" v-else>
              <div class="title">
                <span>会员注册</span>
              </div>
              <div class="reg-form">
                <div class="account">
                  <span class="iconfont icon-shoujihao"></span>
                  <el-input
                    v-model="account"
                    placeholder="请输入手机号"
                  ></el-input>
                </div>
                <div class="verification-code">
                  <span class="iconfont icon-yanzhengma"></span>
                  <el-input
                    v-model="verificationCode"
                    placeholder="请输入验证码"
                  ></el-input>
                  <div
                    v-if="codeShow"
                    class="getVeriCode"
                    @click="getPhoneCode"
                  >
                    获取验证码
                  </div>

                  <span v-if="!codeShow" class="count"
                    >{{ count }}秒后重试</span
                  >
                </div>
                <div class="password">
                  <span class="iconfont icon-mima"></span>
                  <el-input
                    v-model="password"
                    placeholder="请设置密码"
                    show-password
                  ></el-input>
                </div>
                <div class="other">
                  <div class="submit" @click="handleReg">提交</div>
                  <div class="back" @click="gotoLogin">返回</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 页脚 -->
    <div class="foot">
      <footbar></footbar>
    </div>

    <!-- 侧边栏 -->
    <sidebar></sidebar>
  </div>
</template>

<script>
import navigation from "../../components/Navigation/index";
import footbar from "../../components/Footbar/index";
import sidebar from "../../components/Sidebar/index";
import { getSmsCode, handleUserReg, handleUserLogin } from "@/api/MemberCenter";
import Cookies from "js-cookie";

export default {
  components: {
    navigation,
    footbar,
    sidebar,
  },

  data() {
    return {
      // 页面标题
      textTitle: "用户登录",

      // 账号/手机号
      account: "",

      // 密码
      password: "",

      // 验证码及返回参数
      verificationCode: "",
      requestId: "",

      // 控制表单显示
      isShow: true,

      // 控制倒计时
      codeShow: true,
      count: "",
      timer: null,
    };
  },

  mounted() {
    // this.removeAnimate();
    this.init();
  },

  methods: {
    // 判断用户是否已登录
    init() {
      if (Cookies.get("Admin-Token")) {
        this.$router.push({ path: "/MemberCenter/OrderAndInfo" });
      }
    },

    // 初始化时默认无动画
    // removeAnimate() {
    //   const element = document.querySelector("#loginDiv");
    //   element.classList.remove("animate__animated", "animate__fadeInLeft");
    // },

    // 跳转到注册
    gotoReg() {
      // const element = document.querySelector("#loginDiv");
      // element.classList.add("animate__animated", "animate__fadeOutRight");
      // element.addEventListener("animationend", () => {
      //   this.isShow = false;
      // });
      this.isShow = false;
    },

    // 跳转到登录
    gotoLogin() {
      // const element = document.querySelector("#regDiv");
      // element.classList.add("animate__animated", "animate__fadeOutLeft");
      // element.addEventListener("animationend", () => {
      //   this.isShow = true;
      // });

      this.isShow = true;
    },

    // 会员登录
    handleLogin() {
      let data = {
        password: this.password,
        username: this.account,
      };

      handleUserLogin(data).then((res) => {
        // console.log(res);
        if (res.code == 200) {
          Cookies.set("Admin-Token", res.token);
          Cookies.set("account", this.account);
          Cookies.set("user_Id", res.userId);
          this.$router.push({ path: "/MemberCenter/OrderAndInfo" });
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    // 获取手机验证码
    getPhoneCode() {
      var Reg =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (Reg.test(this.account)) {
        const TIME_COUNT = 60;
        if (!this.timer) {
          this.count = TIME_COUNT;
          this.codeShow = false;
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= TIME_COUNT) {
              this.count--;
            } else {
              this.codeShow = true;
              clearInterval(this.timer);
              this.timer = null;
            }
          }, 1000);
        }

        let data = {
          phone: this.account,
        };
        getSmsCode(data).then((res) => {
          // console.log(res);
          if (res.code == 0) {
            this.$message({
              message: "验证码发送成功",
              type: "success",
            });

            this.requestId = res.data.requestId;
          } else {
            this.$message.error(res.data.msg);
          }
        });
      } else {
        this.$message.error("手机号填写有误");
      }
    },

    // 注册
    handleReg() {
      let data = {
        code: this.verificationCode,
        password: this.password,
        phone: this.account,
        requestId: this.requestId,
      };

      handleUserReg(data).then((res) => {
        if (res.code == 200) {
          this.$message({
            message: "会员注册成功",
            type: "success",
          });

          setTimeout(() => {
            location.reload();
          }, 2000);
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    // 初始化数据
    reset() {
      this.verificationCode = "";
      this.password = "";
      this.account = "";
      this.requestId = "";

      this.codeShow = true;
    },
  },
};
</script>

<style scoped>
.content-wrap {
  position: relative;
  width: 100%;
  height: 65%;
}

.back-img {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.back-img img {
  width: 100%;
  height: 100%;
}

.back-img .mask {
  position: absolute;
  z-index: -1;
  bottom: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(
    to right,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.5) 50%,
    rgba(0, 0, 0, 1) 100%
  );
}

.navigatoin-wrap {
  margin: 0 auto;
  width: 90%;
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translate(-50%);
  display: flex;
  justify-content: flex-end;
  z-index: 2;
}

.login-wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -40%);
  width: 50%;
  height: 75%;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
}

.login-wrap .left {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;
  height: 100%;
  background: rgba(20, 20, 21, 0.7);
}

.login-wrap .right {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
  height: 100%;
  background-color: #fff;
}

.login-wrap .left .left-content-wrap {
  width: 95%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.left-content-wrap img {
  align-self: center;
  width: 88px;
  height: 88px;
}

.left-content-wrap span:nth-child(2) {
  margin-left: 12%;
  margin-top: 35px;
  font-size: 2.35rem;
  font-family: STZhongsong;
  font-weight: 400;
  color: #ffffff;
}

.left-content-wrap span:nth-child(3) {
  margin-right: 8%;
  margin-top: 35px;
  align-self: flex-end;
  font-size: 20px;
  font-family: STZhongsong;
  font-weight: 400;
  color: #ffffff;
}

.login-wrap .right .right-content-wrap {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.login-form-wrap,
.reg-form-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}

.login-form-wrap .title span,
.reg-form-wrap .title span {
  font-size: 25px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #323232;
}

.login-form,
.reg-form {
  margin-top: 15%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.login-form .account,
.login-form .password,
.reg-form .account,
.reg-form .verification-code,
.reg-form .password {
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  border-bottom: 1px solid #323232;
  padding: 15px 3px;
}

.login-form .account span,
.login-form .password span,
.reg-form .account span,
.reg-form .verification-code span,
.reg-form .password span {
  margin-bottom: 3px;
  font-size: 24px;
  color: #323232;
}

.login-form .password,
.reg-form .verification-code,
.reg-form .password {
  margin-top: 10%;
}

::v-deep .el-input {
  margin-bottom: 3px;
}

::v-deep .el-input__inner {
  border: none;
  color: #323232;
}

.reg-form .verification-code .el-input {
  width: 60%;
}

.login-form .login-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10%;
  width: 192px;
  height: 43px;
  background: #ff7800;
  border-radius: 10px;
  font-size: 18px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #ffffff;
  cursor: pointer;
}

.login-form .other,
.reg-form .other {
  margin-top: 10%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-form .other span:nth-child(1) {
  font-size: 18px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #5b5b5b;
}

.login-form .other span:nth-child(2) {
  margin-left: 10px;
  font-size: 20px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #ff7800;
  cursor: pointer;
}

.reg-form .verification-code .getVeriCode {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5.3125rem;
  height: 1.75rem;
  background: #ffffff;
  border: 2px solid #ff7800;
  border-radius: 0.625rem;
  font-size: 0.875rem;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #ff8516;
  cursor: pointer;
}

.reg-form .other .submit,
.reg-form .other .back {
  width: 110px;
  height: 43px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.reg-form .other .submit {
  background-color: #ff7800;
  font-size: 18px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #ffffff;
}

.reg-form .other .back {
  margin-left: 16px;
  border: 1px solid #ff7800;
  font-size: 18px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #5b5b5b;
}

.reg-form-wrap .reg-form .verification-code .count {
  font-size: 0.6rem;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #5b5b5b;
}

.foot {
  width: 100%;
  height: 35%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
